<template>
  <section class="ecommerce-application app-ecommerce-details">

    <b-overlay v-if="product && productLoaded" spinner-medium>
      <b-card no-body>
        <b-card-body>
          <b-row class="my-2">
            <b-col class="d-flex align-items-start justify-content-center mb-2 mb-md-0 mt-2" cols="4">
              <div v-if="product.mainVariant.files.length < 2" class="d-flex align-items-center justify-content-center">
                <div class="product-img">
                  <b-img
                    :src="product.mainVariant.files.length ? product.mainVariant.files[0].fileUrls[300] : require('@/assets/images/logo/logo.png')"
                    fluid
                  />
                </div>
              </div>
              <div v-else class="d-flex align-items-center justify-content-center w-100">
                <swiper :options="swiperOptions" class="swiper-responsive-breakpoints">
                  <swiper-slide v-for="file in product.mainVariant.files" :key="file.id">
                    <div class="img-container">
                      <b-img
                        :src="file.fileUrls[300]"
                        fluid
                      />
                    </div>
                  </swiper-slide>
                  <!-- Add Arrows -->
                  <div slot="button-next" class="swiper-button-next" />
                  <div slot="button-prev" class="swiper-button-prev" />
                </swiper>
              </div>
            </b-col>

            <b-col cols="8">
              <div>
                <h2>{{ product.name | trans }}</h2>
                <p>{{ product.mainCategory.name | trans }}</p>
                <p>{{ $t('common.by') }} {{ product.organization.legalName }}</p>
                <div v-if="product.minPrice">
                  <p class="pb-0 mb-0">{{ $t('pricing.total_price_before_tax') }} : {{ product.minPrice.displayPriceExcludingTaxes | priceFormat }}</p>
                  <p class="pb-0 mb-0">{{ $t('pricing.total_price_including_tax') }} : {{ product.minPrice.displayPriceIncludingTaxes | priceFormat }}</p>
                </div>

                <h5 v-if="product.mainVariant && product.mainVariant.variantAttributes && product.mainVariant.variantAttributes.length" class="font-weight-bold mt-3">
                  {{ $tc('service.additional_informations.characteristic', product.mainVariant.variantAttributes.length) }}
                  :
                </h5>
                <ul v-if="product.mainVariant && product.mainVariant.variantAttributes && product.mainVariant.variantAttributes.length">
                  <li v-for="attribute in product.mainVariant.variantAttributes" :key="attribute.id">
                    {{ attribute.attribute.name | trans }} :
                    <span v-if="attribute.inputValues">{{ attribute.inputValues | trans }}</span>
                    <span v-if="attribute.selectValue">{{ attribute.selectValue.name | trans }}</span>
                    <ul v-if="attribute.choiceValues">
                      <li v-for="choice in attribute.choiceValues" :key="choice.id">
                        {{ choice.name | trans }}
                      </li>
                    </ul>
                  </li>
                </ul>

                <h5 class="font-weight-bold mt-2">
                  {{ $t('trip.mandatory_package.discount') }} : {{ product.mainVariant.discount }}%
                </h5>

                <h5 class="font-weight-bold mt-2">
                  {{ $t('trip.mandatory_package.package_services') }}
                </h5>
                <ul>
                  <li v-for="(packageService, index) in packageServices" :key="packageService.id" class="mb-1">
                    <p class="pb-0 mb-0"> {{ $t('trip.mandatory_package.product') }} {{ index + 1 }} : {{ packageService.name | trans }} </p>
                    <p v-if="packageService.packing" class="pb-0 mb-0"> {{ $t('trip.mandatory_package.unit_of_measure') }} : {{ packageService.packing.name | trans }}</p>
                    <p v-if="packageService.quantity && packageService.minPrice && packageService.minPrice.displayPriceIncludingTaxes" class="pb-0 mb-0"> {{ $t('trip.mandatory_package.quantity') }} : {{ packageService.quantity }}</p>
                    <p v-if="packageService.minPrice && packageService.minPrice.displayPriceIncludingTaxes" class="pb-0 mb-0"> {{ $t('trip.mandatory_package.unit_price_before_tax') }} : {{ packageService.minPrice.displayPriceIncludingTaxes | priceFormat }}</p>
                    <p v-else class="pb-0 mb-0"> {{ $t('trip.mandatory_package.unit_price_before_tax') }} :  {{ $t('trip.shop.price_on_quote') }}</p>
                    <p v-if="packageService.vatRate" class="pb-0 mb-0"> {{ $t('trip.mandatory_package.tva') }} : {{ packageService.vatRate }}%</p>

                  </li>
                </ul>

              </div>
              <div class="d-flex align-items-center justify-content-end mb-1 mt-4">
                <h4 class="item-price m-0">
                  <span v-if="!product.minPrice" class="h5">
                    {{ $t('trip.shop.price_on_quote') }}
                  </span>
                  <span v-else> {{ product.minPrice.displayPriceIncludingTaxes | priceFormat }} {{ $t('trip.hangar.incl_taxes') }}</span>
                </h4>
                <b-button v-if="!selected" :disabled="!($route.query.start_at && $route.query.end_at)" class="btn-cart ml-1" @click="confirmation(product)">
                  <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                  <span>{{ $t('trip.shop.add_to_cart') }}</span>
                </b-button>
                <b-button
                  v-else
                  :to="{
                    name: 'shop-checkout',
                    params: {
                      provider_id: product.organization.id,
                      trip_id: $route.params.trip_id,
                      step_id: $route.params.step_id,
                      event_id: $route.params.event_id,
                      airport_id: $route.params.airport_id,
                    },
                  }"
                  class="btn-cart ml-1"
                >
                  <feather-icon class="mr-50" icon="ShoppingCartIcon" />
                  <span>{{ $t('trip.shop.action.view_in_cart') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>

      </b-card>

      <b-card v-if="product.mainVariant.description && product.mainVariant.description[lang]">
        <h3>{{ $t('common.description') }}</h3>
        <div v-html="product.mainVariant.description[lang]" />
      </b-card>
    </b-overlay>

  </section>
</template>
<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { fetchMandatoryPackageProductDetailRequest } from '@/request/globalApi/requests/apiRequests'
import { mapState } from 'vuex'

export default {
  name: 'MandatoryPackageDetail',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      productLoaded: false,
      product: null,
      packpackageServices: null,
      productQuantity: 1,
      relatedProducts: null,
      selected: '',
      tripData: {},
      tripStep: null,
      confirmationEdition: false,
      selectedProduct: null,
    }
  },
  computed: {
    ...mapState('appConfig', ['lang']),
  },
  watch: {
    $route() {
      this.APIFetchProduct()
    },
  },
  mounted() {
    this.APIFetchProduct()
  },
  methods: {
    APIFetchProduct() {
      this.productLoaded = false
      const productId = this.$route.params.product_id
      const organizationId = this.$route.params.provider_id
      const tripStepId = this.$route.params.event_id
      fetchMandatoryPackageProductDetailRequest(tripStepId, organizationId, productId)
        .then(r => {
          const { product, selected, packageServices } = r.data
          this.product = product
          this.packageServices = packageServices

          this.selected = true
        })
        .catch(() => {
          this.product = undefined
        })
        .finally(() => {
          this.productLoaded = true
        })
    },
    confirmation(product) {
      this.selectedProduct = product
      this.confirmationEdition = true
    },
    postOffer() {
      this.selected = true
    },
  },
  setup() {
    const swiperOptions = {
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }

    return {
      swiperOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '../../../../node_modules/swiper/css/swiper.css';
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>
